import { render } from 'preact';
import { getConsent, subscribeToConsent } from '@vgno/utils';
import HeiDynamicTeaser from './HeiDynamicTeaser.jsx';

const fetchPrepromptData = async () => {
    try {
        const response = await fetch(
            'https://www.vg.no/spesial/drive/1sovSkmkUidChHcm4vdn1xGVdGiA4qvaWPA0Am4_p1Ag.json',
        );
        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }
        const data = await response.json();
        const prodData = data.data.PROD;
        const maxRelevance = Math.max(
            ...prodData.map((item) => Number(item['Relevans (0-5)'])),
        );

        const highestRelevanceItems = prodData.filter(
            (item) => Number(item['Relevans (0-5)']) === maxRelevance,
        );

        const firstItem =
            highestRelevanceItems[
                Math.floor(Math.random() * highestRelevanceItems.length)
            ];

        const remainingItems = prodData
            .filter((item) => item !== firstItem)
            .sort(() => Math.random() - 0.5);

        const finalList = [firstItem, ...remainingItems];

        return finalList.slice(0, 3);
    } catch (error) {
        console.error(error);
    }
};

const renderWithUnleash = async (props) => {
    const { node, getUnleashClient } = props;

    const unleashClient = await getUnleashClient();

    const unleashToggleName = 'hei-dynamic-teaser-front';
    const isEnabled = unleashClient.isEnabled(unleashToggleName);

    if (isEnabled) {
        const preprompts = await fetchPrepromptData();
        console.log('Hei VG Dynamic Teaser: Feature toggle enabled, rendering');

        node.style.display = 'block';
        return render(
            <HeiDynamicTeaser preprompts={preprompts} {...props} />,
            node,
        );
    }
};

// We export a function that receives props, including "props.node"
export default async function initHeiVg(props) {
    console.log('Hei VG Teaser: Initializing');

    // Ensure we have a valid HTMLElement to mount onto
    const { node } = props;
    if (!node || !(node instanceof HTMLElement)) {
        console.error('No valid node found for Hei VG Teaser');
        return;
    }

    const consent = await getConsent('CMP:personalisation');
    let initialized = false;

    if (consent) {
        console.log(
            'Hei VG Teaser: Consent already given, checking feature toggle',
        );

        return await renderWithUnleash(props);
    } else {
        subscribeToConsent('CMP:personalisation', async (consented) => {
            if (consented && !initialized) {
                initialized = true;
                console.log(
                    'Hei VG Teaser: Consent eventually given, checking feature toggle',
                );

                return await renderWithUnleash(props);
            }
        });
    }
}
