import { useEffect, useState } from 'react';
import { trackEngagement, trackImpression } from './tracking.js';
import HeiLogo from './HeiLogo.jsx';
import styles from './HeiDynamicTeaser.module.css';

const HeiTeaser = ({ node, preprompts }) => {
    const [inputQuestion, setInputQuestion] = useState('');

    useEffect(() => {
        trackImpression(node, {
            preprompts: preprompts.map((preprompt) => preprompt['Spørsmål']),
        });
    }, []);

    const url = new URL('https://vg.no/hei?utm_source=front-dynamic-teaser');

    const handleSubmit = (e) => {
        e.preventDefault();
        const encodedQuestion = encodeURIComponent(inputQuestion);

        trackEngagement('Click', {
            contentId: 'submit',
        });

        url.searchParams.set('q', encodedQuestion);

        window.location.href = url.toString();
    };

    const handleFocus = () => {
        trackEngagement('Click', {
            contentId: 'focus',
        });
    };

    return (
        <div className={styles.container}>
            <HeiLogo />

            <p className={styles.header}>
                Prøv VGs nye AI-chat, <br />
                tjenesten er ikke redaksjonell
            </p>

            <div className={styles.preprompts}>
                {preprompts.map((preprompt) => {
                    const question = preprompt['Spørsmål'];
                    const encodedQuestion = encodeURIComponent(question);
                    url.searchParams.set('q', encodedQuestion);

                    return (
                        <a
                            key={question}
                            className={styles.preprompt}
                            href={url.toString()}
                            onPointerUp={() => null}
                            onClick={() => {
                                trackEngagement('Click', {
                                    contentId: question,
                                });
                            }}
                        >
                            {question}
                        </a>
                    );
                })}
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Utforsk VGs nye AI-chat"
                    onChange={(e) => {
                        setInputQuestion(e.target.value);
                    }}
                    className={styles.input}
                    value={inputQuestion}
                    onFocus={handleFocus}
                />
                <button
                    type="submit"
                    className={styles.submitButton}
                    disabled={!inputQuestion}
                >
                    {/* ARROW UP ICON */}
                    <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.40781 4.83628C9.30234 4.72612 9.15468 4.66284 9 4.66284C8.84531 4.66284 8.7 4.72612 8.59218 4.83628L4.65468 8.96128C4.43906 9.18628 4.44843 9.54253 4.67343 9.75581C4.89843 9.96909 5.25468 9.96206 5.46797 9.73706L8.4375 6.62925L8.4375 14.6003C8.4375 14.9121 8.68828 15.1628 9 15.1628C9.31172 15.1628 9.5625 14.9121 9.5625 14.6003L9.5625 6.62925L12.5297 9.7394C12.7453 9.9644 13.0992 9.97144 13.3242 9.75815C13.5492 9.54487 13.5562 9.18862 13.343 8.96362L9.40547 4.83862L9.40781 4.83628Z"
                            fill="var(--button-filled-color)"
                        />
                    </svg>
                </button>
            </form>
        </div>
    );
};

export default HeiTeaser;
